import { React, useEffect, useState, useMemo } from 'react';

import { Table, TextFilter, Pagination, Header } from '@cloudscape-design/components';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { COLUMN_DEFINITIONS, columnDisplay } from './FinanceTableColumns';
import { TableEmptyState, TableNoMatchState, pluralizeMatches, formatSelectionFraction } from '../../Common/CommonComponents';

import { useDispatch, useSelector } from 'react-redux';

import { fetchBills, fetchTransactions } from '../../network';


const REC_PER_PAGE = 10;

const FinanceTable = () => {
  const dispatch = useDispatch();

  const setBills = useMemo(()=> bills=>
    dispatch({ type: 'setBills', payload: bills }), [dispatch]);
  
  const setTransactions = useMemo(()=> transactions=>
    dispatch({ type: 'setTransactions', payload: transactions }), [dispatch]);
  
  useEffect(() => {
    Promise
      .all([ fetchTransactions(), fetchBills() ])
      .then(([transactions, bills]) => {
        setTransactions(transactions);
        setBills(bills);
      })
      .catch((errText) => console.log(errText));
  }, []);


  const bills = useSelector(state=> state.bills);
  
  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    bills,
    {
      filtering: {
        empty: <TableEmptyState resourceName='bills' />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />,
      },
      pagination: { pageSize: REC_PER_PAGE },
      sorting: { defaultState: { sortingColumn: COLUMN_DEFINITIONS[4] } },
      selection: {},
    }
  );

  const setSelectedBills = useMemo(()=>
    (bills)=> dispatch({ type: 'setSelectedBills', payload: bills }), [dispatch]);

  const selectedBills = useMemo(()=> (
    collectionProps.selectedItems.filter(i => i.sourceType === 'bills')
  ), [collectionProps.selectedItems]);

  useEffect(()=> void (
    setSelectedBills(selectedBills)
  ), [setSelectedBills, selectedBills]);

  const selectionFractionBills = useMemo(()=> (
    formatSelectionFraction(bills, collectionProps.selectedItems)
  ), [bills, collectionProps.selectedItems]);

  const countTextBills = useMemo(()=> (
    pluralizeMatches(filteredItemsCount)
  ), [filteredItemsCount]);


  
  const transactions = useSelector(state=> state.transactions);

  const {
    items: Titems,
    actions: Tactions,
    filteredItemsCount: TfilteredItemsCount,
    collectionProps: TcollectionProps,
    filterProps: TfilterProps,
    paginationProps: TpaginationProps
  } = useCollection(
    transactions,
    {
      filtering: {
        empty: <TableEmptyState resourceName='transactions' />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />,
      },
      pagination: { pageSize: REC_PER_PAGE },
      sorting: { defaultState: { sortingColumn: COLUMN_DEFINITIONS[4] } },
      selection: {},
    }
  );
  
  const setSelectedTransactions = useMemo(()=>
    (transactions)=> dispatch({ type: 'setSelectedTransactions', payload: transactions }), [dispatch]);
  
  const selectedTransactions = (()=> (
    collectionProps.selectedItems.filter(i => i.sourceType === 'transactions')
  ), [collectionProps.selectedItems]);

  useEffect(()=> void (
    setSelectedTransactions(selectedTransactions)
  ), [setSelectedTransactions, selectedTransactions]);


  const selectionFractionTransactions = useMemo(()=> (
    formatSelectionFraction(transactions, collectionProps.selectedItems)
  ), [transactions, collectionProps.selectedItems]);

  const countTextTransactions = useMemo(()=> (
    pluralizeMatches(TfilteredItemsCount)
  ), [TfilteredItemsCount]);


  
  return (
    <div>
      <Table
        {...collectionProps}
        columnDefinitions={COLUMN_DEFINITIONS}
        columnDisplay={columnDisplay}
        items={items}
        loadingText='Loading bills'
        selectionType='multi'
        className='finance-table'
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter bills'
            filteringPlaceholder='Find bills'
            filteringClearAriaLabel='Clear'
            countText={countTextBills}
          />
        }
        header={ <Header counter={selectionFractionBills}>Bills Table</Header>}
        pagination={<Pagination {...paginationProps}/>}
      />

      <Table
        {...TcollectionProps}
        columnDefinitions={COLUMN_DEFINITIONS}
        columnDisplay={columnDisplay}
        items={Titems}
        loadingText='Loading transactions'
        selectionType='multi'
        className='finance-table'
        filter={
          <TextFilter
            {...TfilterProps}
            filteringAriaLabel='Filter transactions'
            filteringPlaceholder='Find transactions'
            filteringClearAriaLabel='Clear'
            countText={countTextTransactions}
          />
        }
        header={ <Header counter={selectionFractionTransactions}>Transactions Table</Header>}
        pagination={<Pagination {...TpaginationProps}/>}
      />
    </div>
  );
}

export default FinanceTable;
