import { createStore } from 'redux';

const initialState = {
  user: {},
  
  sites: [],
  apps: [],
  transactions: [],
  bills: [],
  
  financePage: {},
};


const reducers = {
  setUser: (state, { payload: user })=> ({ ...state, user }),
  
  setSites: (state, { payload: sites })=> ({ ...state, sites }),
  setApps: (state, { payload: apps })=> ({ ...state, apps }),
  setTransactions: (state, { payload: transactions })=> ({ ...state, transactions }),
  setBills: (state, { payload: bills })=> ({ ...state, bills }),

  setSelectedBills: (state, { payload: selectedBills })=> ({
    ...state,
    financePage: { ...state.financePage, selectedBills },
  }),

  setSelectedTransactions: (state, { payload: selectedTransactions })=> ({
    ...state,
    financePage: { ...state.financePage, selectedTransactions },
  }),
};

const rootReducer = (state, action={})=> (reducers[action.type]||(i=>i))(state, action);

export const store = createStore(rootReducer, initialState);
