import { useState, useEffect } from 'react';
import FinanceTable from './Table/FinanceTable';
import '../Home/Home.css';
import { Button, Grid } from '@cloudscape-design/components';

export const Finance = ({ user })=>{

  return (
    <div className={'tabComponent'}>
      {/* <Grid gridDefinition={[{ colspan: 12}]}> */}
      <div className='financePage'>
        <FinanceTable/>
        <Button href='#/home/bills' variant='primary' className='financeButton'>Pay all due bills now</Button>
        <Button href="#/home/admin-bills" variant='primary'>Make a new bill</Button>
      </div>
      {/* </Grid> */}
      
    </div>
  );
}
