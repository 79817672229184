import {StatusIndicator, Button} from '@cloudscape-design/components';
import { createTableSortLabelFn, capitalizeFirstLetter } from '../../Common/CommonComponents';
import DateTimeConverter from '../../Common/DateTimeConverter';

const statusMap = {
  'due': 'error',
  'unpaid': 'error',
  'pending': 'pending',
  'succeeded': 'success',
  'paid': 'success'
};

const ActionButton = (item) => {
  const isBill = item.sourceType === 'bills';
  const isUnpaidBill = isBill && item.status !== 'paid';
  
  const buttonProps = {
    href: isUnpaidBill ? '#/home/bills' : '#/home/transactions',
    className: isUnpaidBill ? 'financeBillButton' : 'financeTransactionButton',
    variant: 'primary',
    fullWidth: true,
  };
  
  const buttonText = isUnpaidBill ? 'Pay Bill' : 'View Transaction';
  
  return <Button {...buttonProps}>{buttonText}</Button>;
};

export const rawColumns = [
  {
    id: 'email',
    header: 'Email',
    cell: e => e.email,
    isRowHeader: true,
    sortingField: 'email',
    key: 0,
  },
  {
    id: 'amount',
    header: 'Amount',
    cell: e => (
      <span className={e.status+'-amount'}>{(e.amount/100).toFixed(2)} {e.currency.toUpperCase()}</span>
    ),
    sortingField: 'amount',
    key: 2,
  },
  {
    id: 'date',
    header: 'Date',
    cell: e => (
      <div>
        <DateTimeConverter dateTime={e.dueDate ? e.dueDate : e.datePaid}/>
      </div>
    ),
    sortingField: 'date',
    key: 4,
  },
  {
    id: 'status',
    header: 'Status',
    cell: item => (
      <StatusIndicator type={statusMap[item.status]}>{capitalizeFirstLetter(item.status)}</StatusIndicator>
    ),
    sortingField: 'status',
    key: 5,
  },
  {
    id: 'action',
    header: 'Action',
    cell: ActionButton,
  },
]

export const COLUMN_DEFINITIONS = rawColumns.map(column => ({ ...column, ariaLabel: createTableSortLabelFn(column) }));

export const columnDisplay = [
  { id: 'email', visible: true, key: 0 },
  { id: 'amount', visible: true, key: 2 },
  { id: 'date', visible: true, key: 4 },
  { id: 'status', visible: true, key: 5 },
  { id: 'action', visible: true, key: 6 }
]
