import { useMemo, useEffect, useState } from 'react';
import {
  fetchSites,
  fetchApps,
  createSite,
  putSite,
  putApp,
  createDeployment,
  createApp,
  createAppDeployment,
  createSub
} from '../network';

import { MySitesTable } from './Table/MySitesTable';
import { DDCodeEditor } from '../Common/DDCodeEditor';

import './MySites.css';


const templateOptions = [
  { value: 'locus-cyan', name: 'Locus Cyan' },
  { value: 'dopetrope-green', name: 'Dopetrope Green' },
  { value: 'dimension-dark', name: 'Dimension Dark' },
  { value: 'indivisible-red', name: 'Indivisible Red' },
  { value: 'transit-magenta', name: 'Transit Magenta' },
  { value: 'formula-green', name: 'Formula Green' },
  { value: 'tessellate', name: 'Tessellate' },
  { value: 'phantom-magenta', name: 'Phantom Magenta' },
  { value: 'spectral-blue', name: 'Spectral Blue' },
  { value: 'standout-light', name: 'Standout Light' },
  { value: 'wideangle-red', name: 'Wideangle Red' },
  { value: 'atmosphere-green', name: 'Atmosphere Green' },
  { value: 'vortex-magenta', name: 'Vortex Magenta' },
  { value: 'slate-light', name: 'Slate Light' },
  { value: 'helios-orange', name: 'Helios Orange' },
  { value: 'txt-yellow', name: 'TXT Yellow' },
  { value: 'dopetrope-cii', name: 'DT CII' },
];

export const MySites = ()=>{

  const [divRef, setDivRef] = useState(null);
  
  const [sites, setSites] = useState([]);
  const [apps, setApps] = useState([]);
  const [currentSite, setCurrentSite] = useState(null);
  const [siteProps, setSiteProps] = useState('');
  const [appProps, setAppProps] = useState('');

  const [newSiteDomain, setNewSiteDomain] = useState('');
  const [newSiteSlug, setNewSiteSlug] = useState('');
  const [newSiteTemplate, setNewSiteTemplate] = useState('locus-cyan');
  
  const [updateSiteTemplate, setUpdateSiteTemplate] = useState('');

  const isAppUpdated = useMemo(()=> {

    try {
      return !!appProps && !!currentSite?.app && (
        JSON.stringify(JSON.parse(currentSite?.app?.appProps), null, 2) !== appProps
      );
    } catch(e) {
      return true;
    }
  }, [currentSite, appProps]);

  const isSiteUpdated = useMemo(()=> {

    try {
      return !!siteProps && !!currentSite && (
        (JSON.stringify(JSON.parse(currentSite.siteProps), null, 2) !== siteProps) ||
        (!!updateSiteTemplate && (updateSiteTemplate !== currentSite.template))
      );
    } catch(e) {
      return true;
    }

  }, [currentSite, siteProps, updateSiteTemplate]);

  const canRenderApp = useMemo(()=> !!currentSite?.app && !(
    currentSite.app?.lastRendered > (currentSite.app?.lastUpdated ?? 0)
  ), [currentSite]);
  
  const canRenderSite = useMemo(()=> !!currentSite && !(
    currentSite.lastRendered > (currentSite.lastUpdated ?? 0)
  ), [currentSite]);

  const setCurrentSiteAndProps = useMemo(()=> ({ site })=> {
    setCurrentSite(site);

    if(!site) return;
    
    let siteProps = site.siteProps;

    try {
      const prettyS = JSON.stringify( JSON.parse(siteProps), null, 2 );
      siteProps = prettyS;
    } catch(e) {
      console.log(e);
    }
    
    setSiteProps(siteProps ?? '');

    let appProps = site?.app?.appProps;

    try {
      const prettyA = JSON.stringify( JSON.parse(appProps), null, 2 );
      appProps = prettyA;
    } catch(e) {
      console.log(e);
    }

    setAppProps(appProps ?? '');

    setUpdateSiteTemplate(site.template);
    
  }, [setCurrentSite, setSiteProps, setAppProps, setUpdateSiteTemplate]);

  const loadSitesAndApps = useMemo(()=> ()=> {
    fetchSites()
      .then(loadedSites=> {

        return Promise.all([
          fetchApps({ slugs: loadedSites.map(s=> s.slug) }),
          Promise.resolve(loadedSites),
        ]);
      
      }).then(([loadedApps, loadedSites]) => {
        setApps(loadedApps);

        const sitesWithApps = loadedSites.map(ls=> ({
          ...ls,
          app: loadedApps.find(a=> a.slug === ls.slug),
          appStatus: loadedApps.find(a=> a.slug === ls.slug)?.status,
        }));
        
        setSites(sitesWithApps)
        
        if( currentSite ){
          setTimeout(()=> {
            try {
              divRef.querySelector('.edit-site-'+currentSite.app.slug).click()
            } catch(e) {}
          }, 100);
        }
        
      }).catch(errText => console.log(errText));
    
  }, [divRef, setSites, setApps, currentSite, setCurrentSite]);
  
  useEffect(()=> {
    loadSitesAndApps();
  }, []);

  const createNewSite = useMemo(()=> ()=> (
    createSite({
      slug: newSiteSlug,
      domain: newSiteDomain,
      siteProps: JSON.stringify({}),
      template: newSiteTemplate,
    }).then(text=> {
      console.log(text);
      // update the user or whatever

      createSub({
        domain: newSiteDomain,
        subTypeDate: 'demo-site yearly 2024',
      }).then(text=>{
        console.log(text);
      });

      fetchSites()
        .then(ns=> (setSites(ns), ns))
        .then((newSites)=>{

          // this should also set the selection in the table
          // and maybe not throw an error for the app props being undefined in JSON parse
          
          setTimeout(()=> setCurrentSiteAndProps({
            site: newSites.find(s=> s.slug === newSiteSlug)
          }), 300);
        })
        .catch(errText => console.log(errText));

    })
  ), [newSiteSlug, newSiteDomain, newSiteTemplate, setCurrentSiteAndProps]);


  const createNewApp = useMemo(()=> ({ site = currentSite })=> (
    createApp({
      slug: site.slug,
      domain: site.domain,
    }).then(text=> {
      console.log(text);
      // update the user or whatever
      loadSitesAndApps();
    })
  ), [currentSite]);

  
  const publishApp = useMemo(()=> ()=> (
    createAppDeployment({
      slug: currentSite.slug,
      appType: 'site',
    }).then(text=> {
      console.log(text);
      // update the user or whatever
      loadSitesAndApps();
    })
  ), [currentSite]);
  
  const updateSite = useMemo(()=> ()=> {

    let nextSiteProps;

    try {
      nextSiteProps = JSON.stringify(JSON.parse(siteProps), null, 2);
    } catch(e) {
      console.log('invalid JSON, not saving');
      return;
    }
    
    return (
      putSite({
        domain: currentSite.domain,
        email: currentSite.email,
        siteProps: nextSiteProps,
        template: updateSiteTemplate,
      }).then(text=> {
        console.log(text);
        // update the user or whatever
        loadSitesAndApps();
      })

    );
  }, [siteProps, currentSite, updateSiteTemplate]);


  const updateApp = useMemo(()=> ()=> {

    let nextAppProps;

    try {
      nextAppProps = JSON.stringify(JSON.parse(appProps), null, 2);
    } catch(e) {
      console.log('invalid JSON, not saving');
      return;
    }
    
    return (
      putApp({
        ...currentSite.app,
        appProps: nextAppProps,
      }).then(text=> {
        console.log(text);
        // update the user or whatever
        loadSitesAndApps();
      })
    );
  }, [appProps, currentSite]);


  const publishSite = useMemo(()=> ()=> (
    createDeployment({
      domain: currentSite.domain,
      email: currentSite.email,
    }).then(text=> {
      console.log(text);
      // update the user or whatever
      loadSitesAndApps();
    })
  ), [currentSite]);

  return (
    <div className='MySites' ref={r=> setDivRef(r)}>

      <MySitesTable
        onSelectSite={setCurrentSiteAndProps}
        onCreateApp={createNewApp}
        sites={sites}
      />

      <button onClick={()=>{
        setCurrentSite(null);
        setSiteProps(null);
      }} >Create New Site</button>

      
      <hr style={{ height: 2, background: '#29AB87'}} />


      { currentSite ? (

          <div>
            
            <label>
              template
              <select value={updateSiteTemplate} onChange={e => setUpdateSiteTemplate(e.target.value)}>
                {
                  templateOptions.map(({ value, name })=> (
                    <option key={value} value={value}>{name}</option>
                  ))
                }
              </select>
            </label>

            <hr style={{ height: 2, background: '#29AB87'}} />

            <label>
              <span>site props</span>
              <DDCodeEditor value={siteProps ?? ''} onChange={setSiteProps}/>
            </label>

            
            <label>
              <span>app props</span>
              <DDCodeEditor value={appProps ?? ''} onChange={setAppProps}/>
            </label>


            <div className='button-tray-bottom'>
              <button onClick={updateApp} disabled={!isAppUpdated}>Save App</button>
              <button onClick={publishApp} disabled={!canRenderApp}>Publish App</button>
              
              <button onClick={updateSite} disabled={!isSiteUpdated}>Save Site</button>
              <button onClick={publishSite} disabled={!canRenderSite}>Publish Site</button>
            </div>
          </div>
      ) : (
          <div>
            <h2>Create Site</h2>
            <label>
              Domain
              <input value={newSiteDomain} onChange={e=> setNewSiteDomain(e.target.value)} />
            </label>

            <label>
              slug
              <input value={newSiteSlug} onChange={e=> setNewSiteSlug(e.target.value)} />
            </label>

            <label>
              template
              <select value={newSiteTemplate} onChange={e => setNewSiteTemplate(e.target.value)}>
                {
                  templateOptions.map(({ value, name })=> (
                    <option key={value} value={value}>{name}</option>
                  ))
                }
              </select>
            </label>
            
            <button onClick={createNewSite}>Create Site</button>
          </div>
        )}
    </div>
  );
};
