import React from 'react';
import { Box, Button, SpaceBetween } from '@cloudscape-design/components';

// Text for elements counter in the table

export const formatSelectionFraction = (items, selectedItems) => (
  selectedItems && selectedItems?.length > 0 ? (
    `(${selectedItems.length}/${items.length})`
  ) : `(${items.length})`
);

// Table state if there is no match in the filter

export const TableNoMatchState = ({ onClearFilter }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

// Table state if it's empty

export const TableEmptyState = ({ resourceName }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No {resourceName.toLowerCase()}</b>
        <Box variant="p" color="inherit">
          No {resourceName.toLowerCase()} associated with this resource.
        </Box>
      </div>
      <Button>Create {resourceName.toLowerCase()}</Button>
    </SpaceBetween>
  </Box>
);

// Text for filter's matches counter

export const pluralizeMatches = (count) => `${count} ${count === 1 ? 'match' : 'matches'}`;

// Sorting table

export const createTableSortLabelFn = (column) => (
  (!column.sortingField && !column.sortingComparator && !column.ariaLabel) ? (
    undefined
  ) : ({ sorted, descending }) =>
    `${column.header}, ${sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted'}.`
);

// Function to capitalize first letter

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
