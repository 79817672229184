import { useState, useMemo, useEffect } from 'react';
import Modal from '@cloudscape-design/components/modal';

import FormField from '@cloudscape-design/components/form-field';
import Input from '@cloudscape-design/components/input';

import { useNavigate } from 'react-router-dom';

export const LoginForm = ({ email, setEmail, password, setPassword, onComplete })=>{

  return (
    <div className='modal'>
      <FormField
        description='The e-mail for your Dells Digital account'
        label='E-mail'
      >
        <Input
          value={email}
          onChange={event => setEmail(event.detail.value)}
        />
      </FormField>

      <div className='vertical-space-20'/>

      <FormField
        description='The password for your Dells Digital account'
        label='Password'
      >
        <Input
          value={password}
          type='password'
          onChange={event => setPassword(event.detail.value)}
          onKeyDown={e=> e?.detail?.key === 'Enter' ? onComplete() : null}
        />
      </FormField>
    </div>
  );
};

export const LoginModal = ({ onAttempt, onSignup, onReauth })=>{
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [waiting, setWaiting] = useState(false);

  const navigate = useNavigate();

  const attemptLogin = useMemo(()=> (e, p)=>
    onAttempt(e, p).then(()=> navigate('../home'))
                   .catch(()=> {/* display login failed message? */}), []);

  const attemptSignup = useMemo(()=> (e, p)=>
    onSignup(e, p).then(()=> attemptLogin(e, p))
                  .catch(()=> {/* display signup failed message? */}), [attemptLogin]);
  
  const attemptReauth = useMemo(()=> (e, p)=>
    onReauth(e, p).then(()=> console.log('attempt reauth, see email'))
                  .catch(()=> {/* display reauth failed message? */}), []);

  useEffect(()=> {
    if( !localStorage.authToken ) {
      // not logged in!
    } else {
      fetch('me', { headers: { Authorization: localStorage.authToken }})
        .then(res => {
          if( res.status !== 200 ){
            // not logged in!
          } else navigate('../home');
        });
    }
  }, [navigate]);

  
  return (
    <Modal
      className='react-app'
      visible={true}
      onDismiss={()=> navigate('..')}
      header='Login to Dells Digital'
      footer={
        <div className='space-around'>
          <button
            className='primary'
            disabled={waiting}
            onClick={()=> {
              setWaiting(true);
              attemptLogin(email, password).finally(()=> setWaiting(false))
            }}>
            Login
          </button>
          <button
            className='primary'
            disabled={waiting}
            onClick={()=> {
              setWaiting(true);
              attemptSignup(email, password).finally(()=> setWaiting(false))
            }}>
            Sign up
          </button>
          <button
            className='primary'
            disabled={waiting}
            onClick={()=> {
              setWaiting(true);
              attemptReauth(email).finally(()=> setWaiting(false))
            }}>
            Forgot
          </button>
          <button disabled={waiting} onClick={()=> navigate('..')}>Cancel</button>
        </div>
      }
    >
      <LoginForm
        email={email} setEmail={setEmail}
        password={password} setPassword={setPassword}
        onComplete={()=> {
          setWaiting(true);
          attemptLogin(email, password).finally(()=> setWaiting(false))
        }}
      />
    </Modal>
  );
};
