import { useState, useMemo } from 'react';

import DatePicker from '@cloudscape-design/components/date-picker';
import FormField from '@cloudscape-design/components/form-field';

import { Button } from '@cloudscape-design/components';

import { createBill } from '../network';

export const CreateBills = ({ user })=>{
  const [amount, setAmount] = useState(10);
  const [reason, setReason] = useState('');
  const [dueDate, setDueDate] = useState('');

  const makeBill = useMemo(()=> ()=> {
    createBill({
      amount: amount * 100,
      currency: 'usd',
      dueDate: ((new Date(dueDate))-0) + 1000*60*60*12,
      reason,
    })
    
  }, [amount, reason, dueDate]);
  
  return (
    <div className='CreateBills'>
      <label>
        <span>email</span>
        <input value={user.email} onChange={()=> {}}/>
      </label>

      <label>
        <span>amount</span>
        $<input value={amount} type='number' onChange={e=> setAmount(e.target.value)} />.00
      </label>

      <label>
        <FormField
          label='Due Date'
        >
          <DatePicker
            onChange={({ detail }) => setDueDate(detail.value)}
            value={dueDate}
            placeholder='YYYY/MM/DD'
          />
        </FormField>
      </label>

      <label>
        <span>reason</span>
        <input value={reason} onChange={e=> setReason(e.target.value)} />
      </label>

      <Button onClick={makeBill} variant='primary'>Make a new bill</Button>
    </div>
  );
}
