import React, { useMemo, useEffect } from 'react';
import { Table, TextFilter, Pagination, Header } from '@cloudscape-design/components';
import { useCollection } from '@cloudscape-design/collection-hooks';

import { mySitesTableColumnsMemo, columnDisplay } from './MySitesColumns';
import { TableEmptyState, TableNoMatchState, formatSelectionFraction, pluralizeMatches } from '../../Common/CommonComponents';

export const MySitesTable = ({ sites, onSelectSite, onCreateApp }) => {

  const mySitesTableColumns = useMemo(()=> (
    mySitesTableColumnsMemo({
      onCreateApp
    })
  ), [onCreateApp]);
  
  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    sites,
    {
      filtering: {
        empty: <TableEmptyState resourceName='Sites' />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />,
      },
      pagination: { pageSize: 8 },
      sorting: { defaultState: { sortingColumn: mySitesTableColumns[2] } },
      selection: {
        keepSelection: true,
      },
    }
  );

  const countText = useMemo(()=> (
    pluralizeMatches(filteredItemsCount)
  ), [filteredItemsCount]);

  const selectionFraction = useMemo(()=> (
    formatSelectionFraction(sites, collectionProps.selectedItems)
  ), [sites, collectionProps.selectedItems]);

  useEffect(()=> {
    onSelectSite?.({ site: collectionProps.selectedItems[0] });
  }, [
    collectionProps.selectedItems
  ]);

  return (
    <Table
      {...collectionProps}
      columnDefinitions={mySitesTableColumns}
      columnDisplay={columnDisplay}
      items={items}
      loadingText='Loading my sites'
      selectionType='single'
      filter={
        <TextFilter
          {...filterProps}
          filteringAriaLabel='Filter my sites'
          filteringPlaceholder='Find my site'
          filteringClearAriaLabel='Clear'
          countText={countText}
        />
      }
      header={ <Header counter={selectionFraction}>My Sites</Header>}
      pagination={<Pagination {...paginationProps}/>}
    />
  );
};
