export const getAuthed = (url, method = 'GET') => (body)=>
  fetch(url, {
    headers: { Authorization: localStorage.authToken },
    method,
    ...(!body ? {} : { body: JSON.stringify(body) }),
  }).then((res) =>
    res.status === 200
    ? res.json()
    : res.text().then((text) => Promise.reject(text))
  );

export const fetchSites = getAuthed('sites');
export const fetchBills = getAuthed('bills');
export const fetchTransactions = getAuthed('transactions');

export const fetchApps = getAuthed('apps', 'PATCH');

export const createSite = ({
  slug,
  domain,
  siteProps,
  template,
})=>(
  fetch('sites', {
    headers: { Authorization: localStorage.authToken },
    method: 'POST',
    body: JSON.stringify({
      slug,
      domain,
      siteProps,
      template,
    }),
  })
    .then(res => (
      ( res.status === 201 ) ?
      res.text() :
      res.text()
    ))
);

export const createApp = ({
  slug,
  domain,
})=>(
  fetch('apps', {
    headers: { Authorization: localStorage.authToken },
    method: 'POST',
    body: JSON.stringify({
      slug,
      domain,
    }),
  })
    .then(res => (
      ( res.status === 201 ) ?
      res.text() :
      res.text()
    ))
);

export const putSite = ({
  domain,
  email,
  siteProps,
  template,
})=>(
  fetch('sites', {
    headers: { Authorization: localStorage.authToken },
    method: 'PUT',
    body: JSON.stringify({
      domain,
      email,
      siteProps,
      template,
    }),
  })
    .then(res => (
      ( res.status === 200 ) ?
      res.text() :
      res.text()
    ))
);

export const putApp = (app)=>(
  fetch('apps', {
    headers: { Authorization: localStorage.authToken },
    method: 'PUT',
    body: JSON.stringify(app),
  })
    .then(res => (
      ( res.status === 200 ) ?
      res.text() :
      res.text()
    ))
);

export const createDeployment = ({
  domain,
  email,
})=>(
  fetch('publish', {
    headers: { Authorization: localStorage.authToken },
    method: 'POST',
    body: JSON.stringify({
      domain,
      email,
    }),
  })
    .then(res => (
      ( res.status === 200 ) ?
      res.text() :
      res.text()
    ))

);


export const createAppDeployment = ({
  slug,
  appType,
})=>(
  fetch('publish-app', {
    headers: { Authorization: localStorage.authToken },
    method: 'POST',
    body: JSON.stringify({
      slug,
      appType,
    }),
  })
    .then(res => (
      ( res.status === 200 ) ?
      res.text() :
      res.text()
    ))
);


export const createSub = ({
  domain,
  subTypeDate,
})=>(
  fetch('subs', {
    headers: { Authorization: localStorage.authToken },
    method: 'POST',
    body: JSON.stringify({
      domain,
      subTypeDate,
    }),
  })
    .then(res => (
      ( res.status === 200 ) ?
      res.text() :
      res.text()
    ))
);


export const createBill = ({
  amount,
  currency,
  dueDate,
  reason,
})=>(
  fetch('bills', {
    headers: { Authorization: localStorage.authToken },
    method: 'POST',
    body: JSON.stringify({
      amount,
      currency,
      dueDate,
      reason,
    }),
  })
    .then(res => (
      ( res.status === 200 ) ?
      res.text() :
      res.text()
    ))
);
