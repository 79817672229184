import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';

const livePk = 'pk_live_51Nf2cyFXC3p3eau695H6RVZdAyxbA5nazKj9Kn0ljptxDliNliH7ETWPMbQjYOSQXOrwAabJinZMNFnKp0J4DdXc00imaTILen';
const testPk = 'pk_test_51Nf2cyFXC3p3eau6XRKMiPFKgc964UKOBeji2zeXUGmIwBJKQr3U4vftqgb0UxoFbDZLMz3SdrK1trtOeOYPrCV300LNePiH9G';


const stripePromise = loadStripe(localStorage.live ? livePk : testPk);

export const BillsPage = ({ user })=>{
  const [clientSecret, setClientSecret] = useState('');

  const selectedBills = useSelector(state => state.financePage.selectedBills);
  const allBills = useSelector(state => state.bills);

  const allDueBills = useMemo(()=> (
    allBills.filter(bill => (
      ((bill.dueDate-0) < Date.now()) &&
      (bill.status !== 'paid')
    ))
  ), [allBills]);

  const dueNowAmount = useMemo(()=> (
    allDueBills.reduce((p, c)=> p + Number(c.amount), 0)
  ), [allDueBills]);
  
  useEffect(() => {
    if( dueNowAmount )
      // Create PaymentIntent as soon as the page loads
      fetch('/payments/create-intent', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.authToken
      },
        body: JSON.stringify({
          isTest: !localStorage.live,
          amount: dueNowAmount,
          bills: allDueBills,
        }),
      })
      .then((res) => res.json())
      .then(({ clientSecret: cs }) => clientSecret ? null : setClientSecret(cs));
  }, [dueNowAmount, allDueBills]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className='App'>
      <span>Due Amount: ${dueNowAmount/100}.00</span>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm clientSecret={clientSecret} user={user} />
        </Elements>
      )}
    </div>
  );
}

function CheckoutForm({ clientSecret, user }) {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState(user.email ?? '');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe || !clientSecret) return;

    console.log(clientSecret);

    stripe.retrievePaymentIntent(clientSecret).then((piRes) => {
      console.log(piRes);
      const { paymentIntent } = piRes;
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe, clientSecret]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: 'https://www.dellsdigital.com/#/home/finance/paid',
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs'
  }

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <input
        value={email}
        disabled
        onChange={(e) => setEmail(e.target.value)}
      />
      <PaymentElement id='payment-element' options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id='submit'>
        <span id='button-text'>
          {isLoading ? <div className='spinner' id='spinner'></div> : 'Pay now'}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id='payment-message'>{message}</div>}
    </form>
  );
}
