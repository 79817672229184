import { useState, useEffect } from 'react';

import './Home.css';
//import { Dashboard } from '../Dashboard/Dashboard';
import { Finance } from '../Finance/Finance';
import { MySites } from '../MySites/MySites';
import { Products } from '../Products/Products';
import { BillsPage } from '../Bills/BillsPage';
import { CreateBills } from '../Bills/CreateBills';

import { createPortal } from 'react-dom';
import { Route, Routes, Link, Outlet, useNavigate } from 'react-router-dom';

import TopNavigation from '@cloudscape-design/components/top-navigation';

const Dashboard = ()=> <div>Dashboard</div>;

const Signout = ()=>{
  const navigate = useNavigate();

  useEffect(()=> {
    localStorage.authToken = '';
    navigate('/');
  }, [navigate]);

  return null;
};

export const Home = ()=>{

  const [user, setUser] = useState(null);
  const { email, profile: { name, company } = {} } = user ?? {};
  
  const navigate = useNavigate();

  useEffect(()=> {
    document.body.style.overflowY = 'hidden';

    return ()=> {
      document.body.style.overflowY = '';
    };
  }, []);

  
  useEffect(()=> {
    if( !localStorage.authToken ) {
      navigate('../');
    } else {
      fetch('me', { headers: { Authorization: localStorage.authToken }})
        .then(res => {
          if( res.status !== 200 ) navigate('../');
          else {
            // logged in!
            return res.json();
          }
        }).then(setUser);
    }
  }, [navigate]);

  return !user ? (
    <div> waiting gif ... </div>
  ) : createPortal((
    <div className='full-page'>
      <TopNavigation
        identity={{
          href: '#/home',
          title: 'Dells Digital',
        }}
        utilities={[
          {
            type: "button",
            text: "Dashboard",
            href: "#/home/dashboard",
            external: false,
          },
          {
            type: "button",
            text: "MySites",
            href: "#/home/my-sites",
            external: false,
          },
          {
            type: "button",
            text: "My Finances",
            href: "#/home/finance",
            external: false,
          },
          {
            type: "button",
            text: "Products",
            href: "#/home/products",
            external: false,
          },
          {
            type: 'button',
            iconName: 'notification',
            title: 'Notifications',
            ariaLabel: 'Notifications',
            badge: true,
            disableUtilityCollapse: false
          },
          {
            type: 'menu-dropdown',
            iconName: 'settings',
            ariaLabel: 'Settings',
            title: 'Settings',
            items: [
              {
                id: 'settings-org',
                text: 'Organizational settings'
              },
              {
                id: 'settings-project',
                text: 'Project settings'
              }
            ]
          },
          {
            type: 'menu-dropdown',
            text: name + ' @ ' + company,
            description: email,
            iconName: 'user-profile',
            items: [
              { id: 'profile', text: 'Profile' },
              { id: 'preferences', text: 'Preferences' },
              { id: 'security', text: 'Security' },
              {
                id: 'support-group',
                text: 'Support',
                items: [
                  {
                    id: 'documentation',
                    text: 'Documentation',
                    href: '#',
                    external: true,
                    externalIconAriaLabel: '(opens in new tab)'
                  },
                  { id: 'support', text: 'Support' },
                  {
                    id: 'feedback',
                    text: 'Feedback',
                    href: '#',
                    external: true,
                    externalIconAriaLabel: '(opens in new tab)'
                  }
                ]
              },
              {
                id: 'signout',
                text: 'Sign out',
                href: '#/home/signout',
              }
            ]
          }
        ]}
      />
      
      <Routes>
        <Route path='/finance' element={<Finance user={user}/>} />
        <Route path='/finance/paid' element={<div>Payment received... placeholder page</div>} />
        <Route path='/my-sites' element={<MySites/>} />
        <Route path='/products' element={<Products/>} />
        <Route path='/signout' element={<Signout />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/bills' element={<BillsPage user={user}/>} />
        <Route path='/admin-bills' element={<CreateBills user={user}/>} />
        <Route path='/transactions' element={<div>Under construction</div>} />
      </Routes>
    </div>
  ), document.body);
};
