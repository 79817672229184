import './App.css';

import { useMemo } from 'react';

import { HashRouter as Router, Route, Routes, Link, Outlet } from 'react-router-dom';
import { LoginModal } from './Login/Login';
import { Home } from './Home/Home';


const LoginButton = ()=> (
  <Link to='/login' className='button mobile-button'>
    Sign up / Log in
  </Link>
);

function App() {  
  const attemptAuth = useMemo(()=> (email, password)=>
    fetch('login', {
      headers: { 'content-type': 'application/json' },
      method: 'POST', body: JSON.stringify({ email, password }),
    }).then(res=> {
      if(res.status === 200) return res.json();
      else return Promise.reject();
    }).then(({ token })=> {
      localStorage.authToken = token;
    }), []);
  
  const attemptSignup = useMemo(()=> (email, password)=>
    fetch('signup', {
      headers: { 'content-type': 'application/json' },
      method: 'POST', body: JSON.stringify({ email, password }),
    }).then(res=> {
      if(res.status === 201) return res.json();
      else return Promise.reject();
    }), []);
  
  const attemptReauth = useMemo(()=> (email)=>
    fetch('reauth', {
      headers: { 'content-type': 'application/json' },
      method: 'POST', body: JSON.stringify({ email }),
    }).then(res=> {
      if(res.status === 200) return res.json();
      else return Promise.reject();
    }), []);
  
  return (
    <div className='react-app'>
      <Router>
        <Routes>
          <Route path='/' element={<>
            <LoginButton/>
            <Outlet/>
          </>}>
            <Route path='login' element={
              <LoginModal onAttempt={attemptAuth} onSignup={attemptSignup} onReauth={attemptReauth} />
            } />
            <Route path='*' element={<div/>} />
          </Route>
          <Route path='home/*' element={<Home/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
