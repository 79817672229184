import React from 'react';
import { Button } from '@cloudscape-design/components';

export const mySitesTableColumnsMemo = ({
  onCreateApp,
})=> [
  {
    id: 'domain',
    sortingField: 'domain',
    header: 'Domain',
    cell: item => item.domain,
    minWidth: 180,
    ariaLabel: ()=> 'Website Domain',
  },
  {
    id: 'slug',
    sortingField: 'slug',
    header: 'Site Slug',
    cell: item => item.slug,
    minWidth: 120,
    ariaLabel: ()=> 'Unique Site Slug Text',
  },
  {
    id: 'subscriptionPackageType',
    sortingField: 'subscriptionPackageType',
    header: 'Subscription Package Type',
    cell: item => item.subscriptionPackageType,
    minWidth: 100,
    ariaLabel: ()=> 'Subscription Type',
  },
  {
    id: 'appStatus',
    sortingField: 'appStatus',
    header: 'App Status',
    cell: item => item.appStatus,
    minWidth: 100,
    ariaLabel: ()=> 'App status',
  },
  {
    id: 'action',
    header: 'Action',
    minWidth: 100,
    cell: site => site.app ? (
      <div onClick={(e)=> {
        try {
          e.target.parentElement.parentElement.parentElement.querySelector('input').click()
        } catch(e) {}
      }}>
        <Button iconAlign='right' iconName='edit' fullWidth className={'edit-site-'+site.app.slug}>
          Edit Site
        </Button>
      </div>
    ) : (
      <Button onClick={()=> onCreateApp({ site })} iconAlign='right' iconName='multiscreen' fullWidth>
        Deploy Website App
      </Button>
    ),
    ariaLabel: ()=> 'Action Button',
      },
];


    export const columnDisplay = [
      { id: 'domain', visible: true, key: 0 },
      { id: 'slug', visible: true, key: 1 },
      { id: 'appStatus', visible: true, key: 2 },
      { id: 'subscriptionPackageType', visible: true, key: 3 },
      { id: 'action', visible: true, key: 4 }
]
